import {useEffect, useState} from "react";
import {useUserContext} from "../context/user-context-provider";


const useTariffProStatus = () => {
  const {
    userContext: {user},
  } = useUserContext();

  const [status, setStatus] = useState(false);

  useEffect(() => {
    (user?.tariff?.status === "ACTIVE")
      ? setStatus(true)
      : setStatus(false)
  }, [user?.tariff?.status]);

  return {status}
}

export default useTariffProStatus