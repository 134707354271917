import React from "react";
import Link from "next/link";
import type {ClientEventModel} from "../../graphql/get-client-events";
import EventCard from "./event-card";
import css from "./search-events-list.module.scss";
import NoEventsIcon from "../../assets/no-events.svg";
import {Event} from "generated/types";

interface SearchEventsListProps {
  events: Event[];
  favorite: boolean;
  viewMyEvents?: boolean;
}

const SearchEventsList: React.FC<SearchEventsListProps> = (
  {
    events,
    favorite,
    viewMyEvents
  }) => {
  return (
    <>
      {events.length === 0 && (
        <div className={css.Empty}>
          <NoEventsIcon/>
          <div className={css.NoEventsText}>
            {favorite
              ? "Вы не добавили ни одного мероприятия в избранное"
              : "Нет мероприятий, подходящих выбранному фильтру"}
          </div>
          <div className={css.NoEventsDescription}>
            Пожалуйста, измените фильтр для&nbsp;просмотра других мероприятий
          </div>
        </div>
      )}
      {events.length > 0 && (
        <div className={css.Grid}>
          {events.map((item) => (
            <Link key={item.id} href={`/event/${item.id}`} passHref>

              <EventCard event={item} editMode={false} showStatus={viewMyEvents}/>

            </Link>
          ))}
        </div>
      )}
    </>
  );
};

export default SearchEventsList;
