import css from './EventApplication.module.scss'
import {Button, Select} from "ui";
import React, {useState} from "react";
import Textarea from "../../../profile/components/Textarea/Textarea";
import {EditIcon} from "../../../profile/profile.icons";
import {useModalStore} from "../../../../../store/modal";
import {useHostname} from "../../../../application/hooks/useHostname";
import Link from "next/link";
import styles from "../../../applications/modals/IncomeApplication/IncomeApplication.module.scss";
import {PackageModel} from "../../graphql/get-event";
import {useCreateEventApplicationMutation} from "../../../../generated/types";
import {ApolloError} from "@apollo/client";
import {errorNotification, infoNotification} from 'application/notification';

type Props = {
  eventId: number
  eventName: string
  partnerId: number
  partnerBrand: string
  partnerLogo: string
  partnerAddress: string
  partnerFirstName: string
  partnerLastName: string
  partnerPosition: string
  partnerPackage: PackageModel[] | undefined
  myId: number
  myBrand: string
  myFirstName: string
  myLastName: string
  myPosition: string
}

function EventApplication(
  {
    eventId,
    eventName,
    partnerId,
    partnerBrand,
    partnerLogo,
    partnerAddress,
    partnerFirstName,
    partnerLastName,
    partnerPosition,
    partnerPackage,
    myId,
    myBrand,
    myFirstName,
    myLastName,
    myPosition,
  }: Props) {

  const hostname = useHostname();

  const modal = useModalStore();

  const [editMode, setEditMode] = useState(false)

  const initialText = `Здравствуйте, ${partnerFirstName} ${partnerLastName}! \nМеня зовут ${myFirstName} ${myLastName}, ${myPosition} в ${myBrand}. Наша компания хочет поддержать ваше мероприятие. Давайте подробнее обсудим условия сотрудничества.`

  const [text, setText] = useState(initialText)

  const [currentPackage, setCurrentPackage] = useState(partnerPackage ? partnerPackage[0]?.id : '0')

  const [createEventApplication] = useCreateEventApplicationMutation()

  const handleEdit = () => setEditMode(!editMode)

  const handleCancel = () => modal.closeModal()

  const handleSend = () => {
    createEventApplication({
      variables: {
        input: {
          eventId: eventId,
          eventPackageId: Number(currentPackage),
          recipientId: partnerId,
          text: text
        }
      }
    }).then(() => {
      infoNotification('Заявка на мероприятие отправлена')
      modal.closeModal()
    }).catch((error: ApolloError) => {
      if (error.message === 'You blocked') {
        errorNotification('Ошибка: вы не можете отправлять заявки на мероприятия');
      } else {
        errorNotification('Неизвестная ошибка. Попробуйте позже.');
      }
      modal.closeModal()
    })
  }

  const logotype = partnerLogo
    ? process.env.NODE_ENV === "development"
      ? !partnerLogo?.includes("<") ? `http://localhost:8000/${partnerLogo}` : "/img/empty-photo.png"
      : !partnerLogo?.includes("<") ? hostname + "/" + partnerLogo : "/img/empty-photo.png"
    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNrKXDgD3AD6Y3s1ffQgLL-AiAZOa1e8c7cQ&usqp=CAU";

  const packageList = partnerPackage
    ? partnerPackage?.map((item: PackageModel) => {
      return {value: item.id, label: item.name}
    })
    : undefined

  return (
    <div className={css.EventApplication}>
      <h3 className={css.EventApplication__header}>Событие</h3>
      <p>{eventName}</p>

      <h3 className={css.EventApplication__header}>Организатор</h3>
      <div className={css.EventApplication__brand}>
        {partnerLogo ? (
          <>
            <Link href={"/" + partnerAddress}>
              <div className={styles.IncomeApplication__brand__image}>
                <img src={logotype} alt={partnerBrand}/>
              </div>
            </Link>
          </>
        ) : (
          ""
        )}
        <Link href={"/" + partnerAddress}>
          <p>{partnerBrand}</p>
        </Link>
      </div>

      <h3 className={css.EventApplication__header}>Представитель</h3>
      <div className={css.EventApplication__brand}>
        {/*TODO - тут планировалась аватарка*/}
        {/*{logo && // */}
        {/*    <Image src={`${process.env.NEXT_PUBLIC_GRAPHQL_API_URL?.slice(0,-4)}/${logo}`} alt={brand} width={24} height={24}/>*/}
        {/*}*/}
        <p>
          {`${partnerFirstName} ${partnerLastName}, ${partnerPosition}`}
        </p>
      </div>

      {/* TODO-Disabled for future changes */}
      {/*{partnerPackage && partnerPackage?.length > 0 &&*/}
      {/*    <>*/}
      {/*        <h3 className={css.EventApplication__offer}>Партнерский пакет</h3>*/}

      {/*        <Select*/}
      {/*            className={css.EventApplication__select}*/}
      {/*            items={packageList ?? []}*/}
      {/*            value={currentPackage}*/}
      {/*            onSelect={(value: string) => setCurrentPackage(value)}*/}
      {/*        />*/}
      {/*    </>*/}
      {/*}*/}

      {
        editMode
          ? <div className={css.EventApplication__editor}>
            <Textarea
              value={text}
              onChange={setText}
              showCount={(text.length > 490)}
              maxLength={500}
              placeholder={'Напишите, почему и на каких условиях вы хотите сотрудничать с организатором'}
            />
          </div>
          : <>
            <div className={css.EventApplication__message}
                 dangerouslySetInnerHTML={{__html: text.replace(/\n/g, '<br/>')}}/>
            <div className={css.EventApplication__edit} onClick={handleEdit}>
              <EditIcon/>
              <p>Редактировать сообщение</p>
            </div>
          </>
      }

      <div className={css.EventApplication__btns_row}>
        <Button color={"secondary"} onClick={handleCancel}>
          Отмена
        </Button>
        <Button onClick={handleSend} disabled={text.length > 500}>
          Отправить
        </Button>
      </div>
    </div>
  )
}

export default EventApplication