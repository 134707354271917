import s from './TestCreateEvent.module.scss'
import {Button} from 'ui'
import {useModalStore} from "../../../../../store/modal";
import {useRouter} from "next/router";

export default function TestCreateEvent() {

  const router = useRouter()

  const modal = useModalStore();

  const handleCancel = () => {
    modal.closeModal()
  }

  const handleNext = () => {
    router.push('https://t.me/Katrin_Ryazanova_shine')
  }

  return (
    <div className={s.Root}>
      <div className={s.Root__background}></div>
      <p className={s.Root__text}>
        Временно создание мероприятий доступно только вручную через сотрудника поддержки. Нажмите «Продолжить», если
        готовы создать мероприятие сейчас
      </p>
      <div className={s.Root__buttons}>
        <Button color={"secondary-5"} onClick={handleCancel}>
          Отмена
        </Button>
        <Button color={"orange-3"} onClick={handleNext}>
          Продолжить
        </Button>
      </div>
    </div>
  )
}