import s from "./ConnectToUs.module.scss";
import { Button } from "ui";
import { useRouter } from "next/router";

export default function ConnectToUs({
  text = "Чтобы получить доступ к созданию мероприятий на платформе, авторизируйтесь",
  variant = "connect",
  onClose,
}: {
  text?: string;
  variant?: "connect" | "service";
  onClose?: () => void;
}) {
  const router = useRouter();

  const handleEnter = () => {
    router.push("/signin");
  };

  const handleRegister = () => {
    router.push("/signup");
  };

  return (
    <div className={s.Root}>
      <p className={s.Root__text}>{text}</p>
      <div className={s.Root__buttons}>
        {variant === "connect" ? (
          <>
            <Button color={"orange-3"} onClick={handleEnter}>
              Войти
            </Button>
            <Button color={"secondary-5"} onClick={handleRegister}>
              Зарегистрироваться
            </Button>
          </>
        ) : (
          <Button color={"orange-3"} onClick={() => onClose?.()}>
            Хорошо
          </Button>
        )}
      </div>
    </div>
  );
}
