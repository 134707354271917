import React from "react";
import css from "./event-card.module.scss";
import getEventStatus from "features/events/utils/get-event-status";
import {formatDateRange} from "application/utils/dates";
import {EventStatuses} from "../../event-types";
import {CalendarIcon} from "./icons/Calendar";
import {Event} from "generated/types";
import {useHostname} from "application/hooks/useHostname";
import {getImage} from "features/events/utils/get-image";
import Image from 'next/image'

interface EventCardProps {
  event: Event;
  editMode: boolean;
  showStatus?: boolean;
}

const EventCard: React.FC<EventCardProps> = ({
                                               event,
                                               editMode,
                                               showStatus,
                                             }) => {
  const hostname = useHostname();
  const date1 = event.dates.length > 0 ? new Date(event.dates[0][0]) : null;
  const date2 =
    event.dates.length > 0
      ? new Date(event.dates[event.dates.length - 1][1])
      : null;

  let statusClass = css.Status;

  event.status === EventStatuses.DECLINED
    ? (statusClass = css.StatusDeclined)
    : event.status === EventStatuses.MODERATING
      ? (statusClass = css.StatusModerating)
      : event.status === EventStatuses.DRAFT && (statusClass = css.StatusDraft);

  const addressArray: string[] = [];
  if (event.spot) {
    addressArray.push(event.spot);
  }
  if (event.address) {
    addressArray.push(event.address);
  }

  const eventImage = event?.image
    ? event?.image.includes("https://")
      ? event.image
        .split("/")
        .slice(1, event.image.length - 1)
        .join("/")
      : getImage(event.image, hostname)
    : "/img/default-event.jpg"

  return (
    <div
      className={
        date2 && date2.getTime() < new Date().getTime()
          ? css.RootFinished
          : css.Root
      }
    >
      <div className={css.Image}>
        <Image
          src={eventImage}
          alt={event.name ?? ''}
          fill
          sizes="(max-width: 768) 100vw,
                 (max-width: 1024) 50vw,
                 (max-width: 1440) 25w,
                 20vw"
          priority
          quality={75}
          style={{
            objectFit: 'cover',
          }}
        />
        <div style={{
          backgroundImage: `${event.expired ? 'linear-gradient(to top, rgba(255,255,255,0.5), rgba(255,255,255,0.5))' : ''}`,
          position: "absolute",
          width: '100%',
          height: '100%',
          zIndex: 2,
        }}/>

        {event.category &&
          <div className={css.Type}>{event.category}</div>
        }

        {showStatus && (
          <div className={statusClass}>
            {getEventStatus(event.status as any)}
          </div>
        )}
        {/*{event.favorite && <div className={css.Favorite} />}*/}
      </div>
      <div className={css.Main}>
        <div className={css.Date}>
          <CalendarIcon isDark={true}/>
          {date1 && date2
            ? formatDateRange(date1, date2)
            : "Даты проведения не указаны"}
        </div>

        {/*<div className={css.Spot}>{event.spotClient}</div>*/}
        <div className={css.Name}>
          {event.name || (
            <span className={css.Grey}>Заголовок не заполнен</span>
          )}
        </div>
        <div className={css.Tags}>
          {event?.themes &&
            event.themes.map((tag) => {
              return (
                <div className={css.Tag} key={tag}>
                  {tag}
                </div>
              );
            })}
        </div>
        {/*<div className={css.Description}>
          {event.shortDescription || (
            <span className={css.Grey}>Описание не заполнено</span>
          )}
        </div>*/}
        {/*<div className={css.Location}>
          {event.online
            ? "Онлайн"
            : addressArray.join(", ") || "Адрес не указан"}
        </div>*/}

        {/*<div className={css.People}>
          {event.peopleAmountFrom
            ? `от ${event.peopleAmountFrom}${
                event.peopleAmountTo ? ` до ${event.peopleAmountTo}` : ""
              }`
            : "Посещаемость не указана"}
        </div>*/}
      </div>
    </div>
  );
};

export default EventCard;
