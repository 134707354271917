import { TStepTwoForm } from "features/events/components/update-description";
import { TStepOneForm } from "features/events/components/update-event";
import { TStepFourForm } from "features/events/components/update-sponsors";
import { TStepThreeForm } from "features/events/components/update-targets";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

// type EventStep1 = {
//   address?: string;
//   area?: string;
//   category: string;
//   city: string;
//   dates: string[][];
//   isLocationUnknown: boolean;
//   name: string;
//   offline: boolean;
//   online: boolean;
//   themes: string[];
//   times: string[];
//   website: string;
// };

// type EventStep2 = {
//   image?: string; //File | null
//   crop?: {
//     height: number;
//     width: number;
//     x: number;
//     y: number;
//   } | null;
//   removeImage?: boolean;
//   shortDescription: string;
//   fullDescription: string;
// };

// type EventStep3 = {
//   peopleAmountFrom: string;
//   peopleAmountTo: string;
//   priceFrom: string;
//   priceTo: string;
//   free: boolean;
//   model: {
//     b2b: boolean;
//     b2c: boolean;
//   } | null;
//   genderStatements: {
//     male: boolean;
//     female: boolean;
//   };
//   ageStatements: {
//     age00_18: boolean;
//     age18_24: boolean;
//     age25_34: boolean;
//     age35_44: boolean;
//     age45_54: boolean;
//     age55_00: boolean;
//   };
//   eventInterest: string;
// };

// type EventStep4 = {
//   allCategories: boolean;
//   sponsorCategories: string[];
//   addPackages: boolean;
//   packages: {
//     id: string | null;
//     name: string;
//     price: string;
//     barter: boolean;
//     conditions: {
//       id: string | null;
//       name: string;
//     }[];
//   }[];
// };

type EventFormState = {
  eventStep1: TStepOneForm | null;
  eventStep2: TStepTwoForm | null;
  eventStep3: TStepThreeForm | null;
  eventStep4: TStepFourForm | null;
  setEventStep1: (data: TStepOneForm) => void;
  setEventStep2: (data: TStepTwoForm) => void;
  setEventStep3: (data: TStepThreeForm) => void;
  setEventStep4: (data: TStepFourForm) => void;
  draftId: string | null;
  setDraftId: (value: string) => void;
  reset: () => void;
};

export const useEventFormStore = create(
  devtools<EventFormState>((set) => ({
    eventStep1: null,
    eventStep2: null,
    eventStep3: null,
    eventStep4: null,
    setEventStep1: (data: TStepOneForm) =>
      set((state) => ({
        ...state,
        eventStep1: { ...data },
      })),
    setEventStep2: (data: TStepTwoForm) =>
      set((state) => ({
        ...state,
        eventStep2: { ...data },
      })),
    setEventStep3: (data: TStepThreeForm) =>
      set((state) => ({
        ...state,
        eventStep3: { ...data },
      })),
    setEventStep4: (data: TStepFourForm) =>
      set((state) => ({
        ...state,
        eventStep4: { ...data },
      })),
    draftId: null,
    setDraftId: (data: string) =>
      set((state) => ({
        ...state,
        draftId: data,
      })),
    reset: () =>
      set((state) => ({
        ...state,
        eventStep1: null,
        eventStep2: null,
        eventStep3: null,
        eventStep4: null,
        draftId: null
      })),
  }))
);
