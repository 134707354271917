import s from "./BecomePro.module.scss";
import {Button} from "ui";
import {useRouter} from "next/router";
import {RouteEnum} from "../../../../common/enum/route.enum";

export default function BecomePro() {

  const router = useRouter()

  const handleNext = () => {
    router.push(RouteEnum.TARIFFS)
  }

  return (
    <div className={s.Root}>
      <p className={s.Root__text}>
        Подключите подписку PRO, чтобы создавать мероприятия
      </p>
      <div className={s.Root__buttons}>
        <Button color={"orange-3"} responsive onClick={handleNext}>
          Подключить
        </Button>
      </div>
    </div>
  )
}