import { errorNotification } from "application/notification";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Pagination, Spinner } from "ui";
import SearchEventsList from "../components/events-list/search-events-list";
import {
  SelectedFilters,
  UpdateFilter,
} from "../components/search-filters/search-filters";
import { EventFilters } from "../event-types";

import css from "./search-events-page.module.scss";
import {
  DateSortEnum,
  GetEvents,
  GetEventsQueryHookResult,
  useGetEventsQuery,
} from "generated/types";
import PlusIcon from "../assets/plus.svg";
import useTariffProStatus from "../../../application/hooks/useTariffProStatus";
import { useModalStore } from "../../../../store/modal";
import { BecomePro, ConnectToUs, TestCreateEvent } from "../modals";
import { useUserContext } from "../../../application/context/user-context-provider";
import { CloseIcon, LockIcon } from "./search-events-page.svg";
import { useWindowSize } from "react-use";
import { RouteEnum } from "../../../common/enum/route.enum";
import clsx from "clsx";
import { useEventFormStore } from "../../../../store/event-form";

const EventsPage: React.FC<{ favorite?: boolean }> = ({ favorite }) => {
  const router = useRouter();

  const {
    userContext: { user },
  } = useUserContext();

  const [openMenu, setOpenMenu] = useState(false);

  const [viewMyEvents, setViewMyEvents] = useState(false);

  const resetFormState = useEventFormStore((state) => state.reset);

  const searchParams = new URLSearchParams(router.asPath.split("?")[1]);
  const page = searchParams.get("page") ?? "1";

  const updatePageSearchParam = (page: number) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", page.toString());
    router.push(`${RouteEnum.EVENTS}/?${newSearchParams}`);
  };

  const [filters, setFilters] = useState<EventFilters>({
    dates: [null, null],
    online: null,
    cities: [],
    categories: [],
    organization: [],
    themes: [],
    peoples: [null, null],
    targets: [],
    partners: [],
    favorite: favorite || false,
  });

  const [debouncedFilters, setDebounceFilters] = useState(filters);

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    online: [],
    cities: [],
    categories: [],
    organization: [],
    themes: [],
    targets: [],
    partners: [],
  });

  /*const { data: eventsData = result, error: eventsError } = useQuery<
      SearchEventsQuery,
      SearchEventsQueryVars
    >(SEARCH_EVENTS, {
      variables: {
        filters: debouncedFilters,
      },
      fetchPolicy: "network-only",
    });*/

  const eventsPerPage = 12;

  const { data: eventsData, error: eventsError }: GetEventsQueryHookResult =
    useGetEventsQuery({
      variables: {
        input: {
          limit: eventsPerPage,
          offset: (+page - 1) * eventsPerPage,
          dateSort: DateSortEnum.Asc,
          clientId: viewMyEvents ? user?.id : undefined,
        },
      },
      fetchPolicy: "no-cache",
    });

  const { data: myEventsData } = useGetEventsQuery({
    variables: {
      input: {
        limit: 1,
        offset: 0,
        dateSort: DateSortEnum.Asc,
        clientId: user?.id ?? undefined,
      },
    },
    fetchPolicy: "no-cache",
  });

  const hasMyEvents =
    myEventsData?.getEvents?.__typename === "GetEvents"
      ? myEventsData?.getEvents.totalCount > 0
      : false;

  const handlePageChange = (value: number) => {
    updatePageSearchParam(value);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const eventsTotalCount =
    eventsData?.getEvents?.__typename === "GetEvents"
      ? eventsData.getEvents.totalCount
      : 0;
  const eventsTotalPages =
    eventsData?.getEvents?.__typename === "GetEvents"
      ? eventsData.getEvents.totalPages
      : 0;

  const eventsList = useMemo(() => {
    const data = eventsData?.getEvents as GetEvents;
    return data?.events ?? [];
  }, [eventsData]);

  useEffect(() => {
    const timeout = setTimeout(() => setDebounceFilters(filters), 300);

    return () => clearInterval(timeout);
  }, [filters]);

  useEffect(() => {
    if (eventsError) {
      errorNotification(eventsError.message);
    }

    if (eventsData?.getEvents?.__typename === "Error") {
      errorNotification(eventsData?.getEvents?.error);
    }
  }, [eventsData, eventsError]);

  const changeFilters = (updatedFilter: UpdateFilter) => {
    const newFilters = { ...filters };
    const newSelectedFilters = { ...selectedFilters };

    if (updatedFilter.dates !== undefined) {
      newFilters.dates = updatedFilter.dates;
    }

    if (updatedFilter.peoples !== undefined) {
      newFilters.peoples = updatedFilter.peoples;
    }

    if (updatedFilter.online !== undefined) {
      if (newFilters.online === updatedFilter.online.value) {
        newFilters.online = null;
        newSelectedFilters.online = [];
      } else {
        newFilters.online = updatedFilter.online.value;
        newSelectedFilters.online = [updatedFilter.online];
      }
    }

    if (updatedFilter.cities !== undefined) {
      if (newFilters.cities.includes(updatedFilter.cities.value)) {
        newFilters.cities = newFilters.cities.filter(
          (item) => item !== updatedFilter.cities?.value
        );
        newSelectedFilters.cities = newSelectedFilters.cities.filter(
          (item) => item.value !== updatedFilter.cities?.value
        );
      } else {
        newFilters.cities = [updatedFilter.cities.value];
        newSelectedFilters.cities = [updatedFilter.cities];
      }
    }

    if (updatedFilter.categories !== undefined) {
      if (updatedFilter.categories === null) {
        newFilters.categories = [];
        newSelectedFilters.categories = [];
      } else if (
        newFilters.categories.includes(updatedFilter.categories.value)
      ) {
        newFilters.categories = newFilters.categories.filter(
          (item) => item !== updatedFilter.categories?.value
        );
        newSelectedFilters.categories = newSelectedFilters.categories.filter(
          (item) => item.value !== updatedFilter.categories?.value
        );
      } else {
        newFilters.categories = [
          ...newFilters.categories,
          updatedFilter.categories.value,
        ];
        newSelectedFilters.categories = [
          ...newSelectedFilters.categories,
          updatedFilter.categories,
        ];
      }
    }

    if (updatedFilter.organization !== undefined) {
      if (updatedFilter.organization === null) {
        newFilters.organization = [];
        newSelectedFilters.organization = [];
      } else if (
        newFilters.organization.includes(updatedFilter.organization.value)
      ) {
        newFilters.organization = newFilters.organization.filter(
          (item) => item !== updatedFilter.organization?.value
        );
        newSelectedFilters.organization =
          newSelectedFilters.organization.filter(
            (item) => item.value !== updatedFilter.organization?.value
          );
      } else {
        newFilters.organization = [
          ...newFilters.organization,
          updatedFilter.organization.value,
        ];
        newSelectedFilters.organization = [
          ...newSelectedFilters.organization,
          updatedFilter.organization,
        ];
      }
    }

    if (updatedFilter.themes !== undefined) {
      if (updatedFilter.themes === null) {
        newFilters.themes = [];
        newSelectedFilters.themes = [];
      } else if (newFilters.themes.includes(updatedFilter.themes.value)) {
        newFilters.themes = newFilters.themes.filter(
          (item) => item !== updatedFilter.themes?.value
        );
        newSelectedFilters.themes = newSelectedFilters.themes.filter(
          (item) => item.value !== updatedFilter.themes?.value
        );
      } else {
        newFilters.themes = [...newFilters.themes, updatedFilter.themes.value];
        newSelectedFilters.themes = [
          ...newSelectedFilters.themes,
          updatedFilter.themes,
        ];
      }
    }

    if (updatedFilter.targets !== undefined) {
      if (newFilters.targets.includes(updatedFilter.targets.value)) {
        newFilters.targets = newFilters.targets.filter(
          (item) => item !== updatedFilter.targets?.value
        );
        newSelectedFilters.targets = newSelectedFilters.targets.filter(
          (item) => item.value !== updatedFilter.targets?.value
        );
      } else {
        newFilters.targets = [updatedFilter.targets.value];
        newSelectedFilters.targets = [updatedFilter.targets];
      }
    }

    if (updatedFilter.partners !== undefined) {
      if (updatedFilter.partners === null) {
        newFilters.partners = [];
        newSelectedFilters.partners = [];
      } else if (newFilters.partners.includes(updatedFilter.partners.value)) {
        newFilters.partners = newFilters.partners.filter(
          (item) => item !== updatedFilter.partners?.value
        );
        newSelectedFilters.partners = newSelectedFilters.partners.filter(
          (item) => item.value !== updatedFilter.partners?.value
        );
      } else {
        newFilters.partners = [
          ...newFilters.partners,
          updatedFilter.partners.value,
        ];
        newSelectedFilters.partners = [
          ...newSelectedFilters.partners,
          updatedFilter.partners,
        ];
      }
    }

    setFilters(newFilters);
    setSelectedFilters(newSelectedFilters);
  };

  const resetFilters = () => {
    setFilters({
      ...filters,
      dates: [null, null],
      online: null,
      cities: [],
      categories: [],
      organization: [],
      themes: [],
      peoples: [null, null],
      targets: [],
      partners: [],
    });
    setSelectedFilters({
      online: [],
      cities: [],
      categories: [],
      organization: [],
      themes: [],
      targets: [],
      partners: [],
    });
  };

  const { status: isPro } = useTariffProStatus();
  const modal = useModalStore();

  const isLoggedIn = !!user;

  const isOrganizatorProfile = user?.type === "SPOT";

  const { width } = useWindowSize();

  const handleCreateEvent = () => {
    modal.openModal({
      content: <TestCreateEvent />,
      header: "Раздел работает в тестовом режиме",
      onClose: () => {
        modal.closeModal();
      },
      headerStyle: {
        background: "transparent",
      },
      dialogStyle: {
        width: "540px",
        background:
          "url(/img/event-modal-bg.jpg) center center/cover no-repeat",
      },
      closeStyle: {
        width: "16px",
        height: "16px",
        top: "30px",
      },
    });
  };

  const handleConnectToUs = () => {
    const desktopDialogStyle = {
      width: "100%",
      maxWidth: "540px",
      background: "url(/img/event-modal-bg.jpg) center center/cover no-repeat",
    };

    const mobileDialogStyle = {
      width: "100%",
      maxWidth: "480px",
      background:
        "url(/img/event-modal-bg-mobile.jpg) center center/cover no-repeat",
    };

    modal.openModal({
      content: <ConnectToUs />,
      header: (
        <div style={{ paddingRight: "30px" }}>Присоединяйтесь к Eventica</div>
      ),
      onClose: () => {
        modal.closeModal();
      },
      headerStyle: {
        background: "transparent",
      },
      dialogStyle: width <= 768 ? mobileDialogStyle : desktopDialogStyle,
      closeStyle: {
        width: "16px",
        height: "16px",
        top: width <= 480 ? "20px" : "30px",
      },
    });
  };

  const CreateEventButton = ({
    type = "lock",
    onClick,
  }: {
    type: "lock" | "beta";
    onClick: () => void;
  }) => {
    return (
      <div className={css.EventButton}>
        {type === "lock" ? (
          <div className={css.EventButton__label_lock}>
            <LockIcon />
          </div>
        ) : (
          <div className={css.EventButton__label_beta}>
            <p className={css.EventButton__text_beta}>βeta</p>
          </div>
        )}
        <Button color={"primary"} icon={<PlusIcon />} onClick={onClick}>
          Создать мероприятие
        </Button>
      </div>
    );
  };

  const [openProModal, setOpenProModal] = useState(false);

  const BecomeProModal = () => (
    <div
      className={clsx(
        css.ProModalWrapper,
        openProModal && css.ProModalWrapper__open
      )}
    >
      <div className={css.ProModal}>
        <div
          className={css.ProModal__close}
          onClick={() => setOpenProModal(false)}
        >
          <CloseIcon />
        </div>
        <h2 className={css.ProModal__title}>Оформите подписку</h2>
        <p className={css.ProModal__text}>
          Подключите подписку PRO, чтобы создавать мероприятия
        </p>
        <div className={css.ProModal__btn}>
          <Button
            color={"orange-3"}
            onClick={() => router.push(RouteEnum.TARIFFS)}
            responsive
          >
            Подключить
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className={css.Root}>
        <div className={clsx(isLoggedIn ? css.HeaderLogged : css.Header)}>
          <div className={css.Title}>
            {filters.favorite && (
              <Button
                color="secondary"
                className={css.BackButton}
                size="small"
                onClick={() => router.push("/events")}
              >
                <div className={css.BackIcon} />
                Назад
              </Button>
            )}
            {filters.favorite
              ? "Избранные мероприятия"
              : "Поддерживайте мероприятия и повышайте лояльность к вашему бренду"}
          </div>

          {isLoggedIn ? (
            <div
              className={css.SubTitleLogged}
              style={{
                justifyContent: hasMyEvents ? "space-between" : "flex-end",
              }}
            >
              {isLoggedIn && (
                <>
                  {hasMyEvents && (
                    <div className={css.EventsToggle}>
                      <button
                        className={clsx(!viewMyEvents && css.activeBtn)}
                        onClick={() => {
                          window.localStorage.removeItem("eventId");
                          window.localStorage.removeItem("step");
                          setViewMyEvents(false);
                          resetFormState(); // clear event form state
                          updatePageSearchParam(1);
                        }}
                      >
                        Все
                      </button>
                      <button
                        className={clsx(viewMyEvents && css.activeBtn)}
                        onClick={() => {
                          window.localStorage.removeItem("eventId");
                          window.localStorage.removeItem("step");
                          setViewMyEvents(true);
                          resetFormState(); // clear event form state
                          updatePageSearchParam(1);
                        }}
                      >
                        Созданные вами
                      </button>
                    </div>
                  )}
                </>
              )}

              <div className={css.CreateEventBtn}>
                {/* {
                  <CreateEventButton
                    type={"beta"}
                    onClick={() => {
                      window.localStorage.removeItem("eventId");
                      window.localStorage.removeItem("step");
                      resetFormState();
                      router.push("/events/new_event");
                    }}
                  />
                } */}
                {isLoggedIn ? (
                  isOrganizatorProfile ? (
                    <CreateEventButton
                      type={"beta"}
                      onClick={() => {
                        window.localStorage.removeItem("eventId");
                        window.localStorage.removeItem("step");
                        resetFormState();
                        router.push("/events/new_event");
                      }}
                    />
                  ) : isPro ? (
                    <CreateEventButton
                      type={"beta"}
                      onClick={() => {
                        window.localStorage.removeItem("eventId");
                        window.localStorage.removeItem("step");
                        resetFormState();
                        router.push("/events/new_event");
                      }}
                    />
                  ) : (
                    <CreateEventButton
                      type={"lock"}
                      onClick={() => setOpenProModal(true)}
                    />
                  )
                ) : (
                  <CreateEventButton
                    type={"lock"}
                    onClick={handleConnectToUs}
                  />
                )}
              </div>
            </div>
          ) : (
            <CreateEventButton type={"lock"} onClick={handleConnectToUs} />
          )}

          {/*<div className={css.HeaderActions}>
          <button
            className={css.FilterButton}
            onClick={() => setOpenMenu(true)}
          >
            Фильтры
          </button>
          {!filters.favorite && (
            <Button
              className={css.FavoriteButton}
              size="small"
              onClick={() => router.push("/events/favorite")}
            >
              <div className={css.StarIcon} />
              Избранное
            </Button>
          )}
        </div>*/}
        </div>

        <div className={css.Wrapper}>
          {eventsData?.getEvents?.__typename !== "GetEvents" ? (
            <Spinner />
          ) : (
            <SearchEventsList
              events={eventsList}
              favorite={favorite || false}
              viewMyEvents={viewMyEvents}
            />
          )}
          {/*<div className={css.Search}>
          <SearchFilters
            filters={filters}
            selectedFilters={selectedFilters}
            setFilters={changeFilters}
            resetFilters={resetFilters}
            onClose={() => setOpenMenu(false)}
          />
        </div>*/}
        </div>

        {eventsTotalCount > eventsPerPage && (
          <div className={css.Pagination}>
            <Pagination
              //defaultPage={currentPage}
              defaultPage={+page}
              totalPage={eventsTotalPages}
              pageRange={4}
              //onPageChange={handlePageChange}
              onPageChange={(pageNumber) => updatePageSearchParam(pageNumber)}
            />
          </div>
        )}

        {/*<div className={openMenu ? css.FilterMenuOpen : css.FilterMenu}>
        <div className={css.MenuSearch}>
          <div className={css.Close} onClick={() => setOpenMenu(false)} />
          <SearchFilters
            filters={filters}
            selectedFilters={selectedFilters}
            setFilters={changeFilters}
            resetFilters={resetFilters}
            onClose={() => setOpenMenu(false)}
          />
        </div>
      </div>*/}
      </div>

      <BecomeProModal />
    </>
  );
};
export default EventsPage;
